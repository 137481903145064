/**
 * Actions for Fetch Store
 */

export const START_REQUEST = "START_REQUEST";
export const FINISH_REQUEST = "FINISH_REQUEST";
export const SAVE_DATA = "SAVE_DATA";
export const RECEIVE_ERROR = "RECEIVE_ERROR";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_DELETE_STATUS = "DELETE_STATUS";
export const TNC = "TNC";
export const DIAL_CODES = "DIAL_CODES";

export const PATIENT_DETAILS = "PATIENT_DETAILS";
export const DOCTOR_DETAILS = "DOCTOR_DETAILS";

export const SAVE_APPOINTMENT = "SAVE_APPOINTMENT";
export const GET_HISTORY_APPOINTMENTS = "GET_HISTORY_APPOINTMENTS";
export const GET_UPCOMING_APPOINTMENTS = "GET_UPCOMING_APPOINTMENTS";

export const GET_ALL_SPECIALITIES = "GET_ALL_SPECIALITIES";

export const GET_ALL_APPOINTMENTS = "GET_ALL_APPOINTMENTS";
export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const GET_SPECIALTIES = "GET_SPECIALTIES";

export const GET_SPECIALITIES_LIST = "GET_SPECIALITIES_LIST";
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";

export const GET_USER_DOCTOR = "GET_USER_DOCTOR";
export const GET_USER_PATIENT = "GET_USER_PATIENT";
export const GET_DOCTOR_PROFILE = "GET_DOCTOR_PROFILE";
export const GET_DOCTOR_SLOTS = "GET_DOCTOR_SLOTS";
export const BOOK_DOCTOR_SLOTS = "BOOK_DOCTOR_SLOTS";
export const GET_PATIENT_UPCOMING_APPOINTMENTS = "GET_PATIENT_UPCOMING_APPOINTMENTS";
export const GET_PATIENT_HISTORY_APPOINTMENTS = "GET_PATIENT_HISTORY_APPOINTMENTS";
export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const VERIFY_PAYMENT = "VERIFY_PAYMENT";
export const TRANSACTION_STATUS = "TRANSACTION_STATUS";
export const ACTIVE_BRAND = 'ACTIVE_BRAND'
export const LANGUAGE = 'LANGUAGE'
export const MY_DOCTORS = 'MY_DOCTORS'


