import { START_REQUEST, RECEIVE_ERROR, GET_CONFIGURATION, ACTIVE_BRAND } from "../actions/types";

const initialState = {
  configurations: {
    booking: {
      canBookBeforeInMin: '',
      paymentNeedToCompleteInMin: ''
    },
    slotDurationInMinute: '',
    callVia: '',
    deactivateAccountInMin: ''
  },
  isFetching: false,
  isError: false,
  brandData: {}
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false
      });

    case GET_CONFIGURATION:
      return Object.assign({}, state, {
        configurations: Object.assign({}, {
          booking: action.data.booking,
          callVia: action.data.callVia,
          deactivateAccountInMin: action.data.deactivateAccountInMin
        }),
        isFetching: false,
        isError: false
      });
    case ACTIVE_BRAND:
      return Object.assign({}, state, {
        brandData: action.data,
        isError: true,
        isFetching: false
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false
      });
    default:
      return state;
  }
};

export default configurationReducer;