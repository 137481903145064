export const routePath = {
  LOGIN: "/login",
  REGISTER: "/register",
  RESET_PASSWORD: "/resetpassword",
  APPOINTMENTS: "/appointments",
  PROFILE: "/profile",
  ABOUT_US: "/about-us",
  GET_APPOINYMENT_BY_ID: "/appointments/:id",
  SPECIALITIES: "/",
  GET_DOCTORS_BY_ID: "/doctors/:id",
  GET_DOCTOR_PROFILE: "/doctor-profile/:id",
  SLOTS: "/slots",
  GET_SLOTS: "/slots/:id",
  VITALS: "/vitals/:apptid",
};
