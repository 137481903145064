export const en = {
  AppointmentTabs: {
    UPCOMING_TAB: "Upcoming Appointments",
    HISTORY_TAB: "History Appointments",
    NO_DATA: "No data to show",
    EDIT: "edit",
    VIEW: "view",
    PAY: "Pay",
    DOCTORS: "Doctors",
    MY_DOCTORS: "My Doctors"
  },
  BANNER: {
    COOKIE_BANNER_TEXT: " This website uses cookies to ensure you get the best experience on our website.",
    COOKIE_POLICY: "Cookies Policy.",
    COOKIE_BTN: "Got it",
  },
  strings: {
    DASHBOARD: "Dashboard",
    PROFILE: "Profile",
    LOGOUT: "Logout",
    HEADER_TEXT: "Tele Consulting Webapp",
    SIGNIN_TEXT: "Sign In to your account",
    LOGIN_TEXT: "Login",
    OTP_TEXT: "Generate OTP",
    CONNECT: "Connect",
    ALERT: "mobile number should be 10 digits long",
    ALERT1: "Successfully updated",
    ALERT2: "password did not match",
    ALERT3: "enter Email or mobile number",
    SIGNUP_TEXT1: " Don't  have an account?",
    REGISTER_TEXT: "Register",
    CREATE_TEXT: "Create your account",
    LOGIN_TEXT1: "Already have an account ?",
    NEXT: "Next",
    CREATE: "Create Account",
    TC1: "Terms and Conditions",
    TC2: "Please accept the terms and conditions",
    TC3: "tcId",
    TC4: "isTCAccepted",
    TC5: "Please accept the latest terms and conditions",
    TELECONSULTING: "of Tele Consulting",
    AGREE: "I Agree to the ",
    DIAL_CODE: "dialCode",
    EMAIL_MOBILE: "Email or Mobile",
    INSURANCE_PHOTO1: "PLEASE UPLOAD Insurance Photo",
    INSURANCE_PHOTO2: "Insurance Photo",
    EMIRATES_TEXT1: "PLEASE UPLOAD Emirates Id Card",
    EMIRATES_TEXT2: "Emirated Id Card",
    EMIRATES_TEXT3: "Emirates Id",
    EMIRATES_TEXT4: "emiratesId",
    MOBILE_TEXT: "mobileNumber",
    OTP: "otp",
    PASSWORD: "password",
    TEXT: "text",
    CHANGE_TEXT: "CHANGE",
    SAVE_TEXT: "SAVE",
    DOCTOR_ROLE: "User Role",
    FORGOT_PASSWORD: "Forgot password ?",
    NO_NOTIFICATION: "No New Notification",
    RESET_PASSWORD: "Reset Password",
    SUCCESS: "Success",
    SHOW_PASSWORD: "Show password",
    KEY: "key",
    BUTTON1: "OK",
    BUTTON2: "Get OTP on Phone/Email",
    BUTTON3: "RESET",
    BUTTON4: "Accept",
    BUTTON5: "View TC",
    BUTTON6: "Not Now",
    BUTTON7: "Submit",
    MODAL1: "Password successfully reset!!",
    LABEL1: "Email or Phone",
    LABEL2: "OTP",
    LABEL3: "Password",
    LABEL4: "Confirm Password",
    LABEL5: "Dial Code",
    LOGIN_TYPE1: "Login with OTP ?",
    LOGIN_TYPE2: "Login with Password ?",
    MODAL_HEADER: "Rate Your Video Call Quality",
    COMMENT: "Comment",
    MODAL_BODY: "Thanks For The Ratings",
    CANCEL: "Cancel",
    DOWNLOAD: "Download Details",
    PDF1: "Loading document...",
    PDF2: "Download Pdf",
    PDF3: "DETAILS",
    DELETE_ACCOUNT: "Delete Account",
    DEACTIVATE_ACCOUNT: "Delete Account",
    ADD: "Add",
    ALERT4: "Your account is deactivated.You can login after",
    ALERT5: "days",
    BACK: "Back to Personal Information",
    ABOUT: "ABOUT US",
    TOKEN_EXPIRE: "Token expired!!"
  },
  ValidationMessages: {
    EMAIL_MOBILE: "Please enter email or mobile number",
    INVALID_EM: "Invalid Email or Mobile No.",
    OTP1: "Please enter Otp",
    OTP2: "otp cannot be negative",
    OTP3: "Invalid OTP",
    OTP4: "Otp must be 4 digits long",
    PASSWORD1: "Please enter Password",
    PASSWORD2: "password should contain Minimum eight characters",
    PASSWORD3: "Re-enter the password",
    FIRSTNAME: "Please enter FirstName",
    FIRSTNAME2: "Invalid Firstname",
    LASTNAME1: "Please enter LastName",
    LASTNAME2: "Invalid Lastname",
    MOBILE_NO1: "Please Enter Mobile Number",
    MOBILE_NO2: "Mobile no. cannot be negative",
    MOBILE_NO3: "Mobile number must be 10 digits long",
    IMAGE: "Invalid File Format",
    EMAIL: "Invalid Email",
    HEIGHT1: "Height cannot be negative",
    HEIGHT2: "Height cannot be more than 300cm",
    HEIGHT3: "Invalid Height",
    WEIGHT1: "Weight cannot be negative",
    WEIGHT2: "Weight cannot be more than 200kg",
    WEIGHT3: "Invalid Weight",
    EMIRATES1: "Cannot be negative",
    EMIRATES2: "Must be between 5 to 20 digits long",
    DIAL_CODE: "Please select dial code",
    BP: "Invalid BP",
    TEMPERATURE: "Invalid Temperature",
    TEMP_UNIT: "Select Temperature Unit",
  },
  Vitalstrings: {
    UNIT: "mmHg",
    FORMAT: "DD-MM-YYYY",
    APPOINTMENT_DATE: "Appointment Date",
    APPOINTMENT_TIME: "Appointment Time",
    VITAL_HEADER: "Patient Vitals",
    TEXT1: "Your request has been sent for approval",
    TEXT2: "Fill the details (Optional)",
    LABEL1: "Blood Pressure",
    LABEL2: "Temperature",
    LABEL3: "Temperature Unit",
    LABEL4: "Time Of Record",
    BUTTON: "SAVE",
    INVALID: "Invalid Format",
    NAME1: "bloodPressure",
    NAME2: "temperature",
    SELECT: "Select",
    UPLOAD: "Upload",
    CREATE_PRESCRIPTION:"Create Prescription"
  },
  Regex: {
    BLOOD_PRESSURE: /^[0-9]{1,3}\/[0-9]{1,3}$/,
    EMAIL_MOB: /^([a-zA-Z0-9_\-\.]+){2,4}@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$|^[0-9]{10,10}$/,
    OTP: /^[0-9]+$/,
    PASSWORD: /^(?:.*[A-Za-z])(?:.*\d)(?:.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    NAME: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
    MOB_NO: /^[0-9]{10,10}$/,
    IMAGE: /\.(jpg|png|jpeg)$/i,
    FILE: /\.(jpg|png|jpeg|pdf)$/i,
    EMAIL: /^([a-zA-Z0-9_\-\.]+){2,4}@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
    HEIGHT: /^\d{2,3}\.?\d{0,2}$/,
    WEIGHT: /^\d{2,3}\.?\d{0,2}$/,
    TEMPERATURE: /^\d{2,3}\.?(\d{0,1})$/,
  },
  TemperatureUnit: [
    "F",
    "C"
  ],
  notesStrings: {
    NOTES_HEADER: "Notes",
    NOTES_ERR: "No Notes Added yet",
    SEND: "Send",
    ADD_NOTES: "Add Notes",
    NOTES_ERR1: "No notes added",

  },
  prescriptionStrings: {
    PRESCRIPTION_HEADER: "Prescription",
    PRESCRIPTION_ERR: "No Prescription Added yet",
    SEND: "Send",
    ADD_PRESCRIPTION: "Add Prescription",
    Prescription_ERR1: "No Prescription added",

  },
  docsStrings: {
    DOCS_HEADER: "Documents",
    VIEW_PDF: " View PDF",
    NO_DOCS_ERROR: "No Documents shared yet",
    NO_DOCS_ERROR1: "No document shared"
  },
  AppointmentHeadings: [
    "Patient Name",
    "Doctor Name",
    "Booked At",
    "Slot Time",
    "Payment Status",
    "Payment Amount",
    "Appointment Status",
    "Appointment Fee",
    "Action",
  ],
  DoctorsDetailHeadings: [
    "Doctor Id",
    "Name",
    "ConsultationFee",
    "Years Of Experience",
    "Mobile Number",
    "Date of Birth",
    "Email",
    "Specialties Id",
    "Specialty",
    "Total Appointments",
    "Doctor's Qualification",
    "Experience Start Date",
    "About",
  ],
  doctorStrings: {
    FORMAT: "YYYY-MM-DD",
    TEXT1: "No slots to show",
    BUTTON1: "Schedule Appointment",
    LABEL1: "Shift Date",
    ALERT1: "select slot time",
    ID: ":apptid",
    NAME: "Name",
    SPECIALITY: "Specialised In",
    TOTAL_APPTS: "Total Appointments",
    EXPERIENCE: "Experience",
    VIEW_PROFILE: "View Profile",
    SELECT_DATE: "Select Date And Time"
  },
  PatientDetailHeadings: [
    "Email",
    "First Name",
    "Last Name",
    "Mobile Number",
    "Date Of Birth",
    "Age",
    "Gender",
    "Height",
    "Weight",
    "Emirates Id",
    "Emirates Document",
    "Insurance  Document",
    "OTP",
  ],
  SpecialityHeadings: ["Logo", "Name", "Status"],
  AppointmentStatus: {
    1: "Pending",
    2: "Payment Pending",
    3: "Confirmed",
    4: "Rejected",
    5: "Expired",
    6: "Cancelled",
    7: "Failed",
    8: "Expired",
  },
  PaymentConfirmation: {
    1: "Payment Pending",
    2: "PayNow",
    3: "Start",
    4: "Rejected",
    5: "Expired",
    6: "Cancelled",
    7: "Failed",
    8: "Expired",
  },
  BadgeColor: {
    Pending: "warning",
    Confirmed: "success",
    Expired: "danger",
    Failed: "danger",
    Cancelled: "danger",
    WatingForPayment: "secondary",
    Rejected: "danger",
    Completed: "success",
    None: "secondary",
    payNow: "danger",
    Start: "primary",
  },
  ButtonColor: {
    Pending: "warning",
    Confirmed: "success",
    Expired: "danger",
    Failed: "danger",
    Cancelled: "danger",
    WatingForPayment: "secondary",
    Rejected: "danger",
    Completed: "success",
    None: "secondary",
    PayNow: "success",
    Start: "primary",
  },

  PaymentStatus: {
    1: "None",
    2: "Pending",
    3: "Failed",
    4: "Completed",
  },
  AppointmentStrings: {
    APPT_HEADING: "Appointments",
    HEADER_TEXT: "Appointment Details",
    APPT_ID: "Appointment Id",
    SLOT_START: "Slot Start Time",
    SLOT_END: "Slot End time",
    PAYMENT_STATUS: "Payment Status",
    BOOKING_TIME: "Booking Time",
    APPT_STATUS: "Appointment Status",
    PAYMENT_AMT: "Payment Amount",
    UPDATE_BTN: "Update",
    PAYMENT_DETAILS: "Payment Details",
    AMT: "Amount",
    CURRENCY: "Currency",
    METHOD: "Method",
    DESCRIPTION: "Description",
    CARD_ID: "Card Id",
    EMAIL: "Email",
    CONTACT: "Contact",
    DATE: "Date",
    SLOT: "Slots"
  },
  ProfileHeadings: [
    "Appointments",
    "Experience",
    "About Doctor",
    "Consultation Fee",
    "Language",
  ],
  PatientStrings: {
    PATIENT_DETAILS: "Patient Details",
    PATIENT_HEADING: "Profile Details",
    EDIT: "Edit",
    EMIRATES_TEXT4: "emiratesId",
    EMIRATES: "emiratesdId",
    MOBILE_TEXT: "mobileNumber",
    OTP: "otp",
    DOB: "dob",
    ID: "id",
    AGE: "age",
    APPOINTMENT_COUNT: "appointmentCount",
    ALERT1: "enter email",
    OTP_TEXT: "Generate OTP",
    SAVE_TEXT: "SAVE",
    CHANGE_TEXT: "CHANGE",
    YEARS: "years",
    FORMAT: "YYYY-MM-DD",
    NAME1: "profileImage",
    NAME2: "emiratesIdDoc",
    NAME3: "insuranceDoc",
    NAME4: "email",
    OBJECT: "object",
    DELETE_DATA: "Delete Data"
  },
  SpecialityStrings: {
    TEXT1: " Select Speciality",
    TEXT2: "No data to show",
    PLACEHOLDER: "speciality name",
    BOOK_APPT: "Book Appointment",
    SEARCH_SPEC: "Search Speciality",
    BANNER_TEXT: "Cannot book appointments as you had requested to delete your account!!"
  },
  UserRole: {
    1: "Patient",
    2: "Admin",
    3: "Doctor",
    4: "CallCentreExecutive",
    5: "RCMExecutive",
    6: "DoctorAssistant",
  },
  SlotStatus: {
    1: "Open",
    2: "Locked",
    3: "Booked",
  },
  Gender: ["Male", "Female"],

  CallVia: {
    1: "Local",
    2: "Vidyo",
    3: "Trova",
  },
  Currency: {
    INR: "INR",
    AED: "AED",
  },
  ConfigurationStrings: {
    HEADER_TEXT: "Configurations",
    BOOKING_TIME: "Booking TIme (in min)",
    PAYMENT_TIME: "Payment Complete Time (in min)",
    SLOT_TIME: "Slot Duration (in min)",
  },
  BADGE_STATUS: {
    Active: "success",
    Inactive: "danger",
  },
  tncHeadings: ["Id", "Version", "Status", "Action", "View"],
  DocumentType: {
    1: "Prescription",
    2: "Report",
  },
  labels: {
    1: 'Very Dissatisfied',
    2: 'Dissatisfied',
    3: 'Neutral',
    4: 'Satisfied',
    5: 'Very Satisfied',
  },
  footer: {
    TEXT1: "Powered by",
    TEXT2: "Tele Consulting",
  },
  alerts: {
    LOADING: "Loading...",
    ARE_YOU_SURE: "Are you Sure?",
    YES: "Yes",
    CANCEL: "Cancel",
    NO_NOTIFS: "NO NEW NOTIFICATIONS YET",
    RATINGS: "Ratings",
    SELECT: "Select",
    RCM_INACTIVITY: "Expired Due to Rcm Inactivity",
    NON_PAYMENT: "Expired Due to Non-payment",
    ALERT1: "Notes Added Successfully",
    ALERT2: "Please Write something",
    ALERT3: "Image successfully uploaded!!",
    ALERT4: "Invalid Format!!",
    ALERT5: "PDF successfully uploaded!!",
    ALERT6: "Error submitting Document",
    ALERT7: "Your payment is confirmed"
  },
  notFound: {
    CODE: "404",
    TEXT1: "Oops! You're lost.",
    TEXT2: "The page you are looking for was not found.",
    SEARCH: "Search"
  },
  placeholder: {
    TEXT1: "What are you looking for?",
    TEXT2: "Firstname",
    TEXT3: "Lastname",
    TEXT4: "Mobile Number",
    TEXT5: "Otp",
    TEXT6: "Add Comment",
  },
  PATIENT: [
    { text: "Profile Image", value: 'profileImage' },
    { text: "Email", value: 'email' },
    { text: "First Name", value: 'firstName' },
    { text: "Last Name", value: 'lastName' },
    { text: "Mobile Number", value: 'mobileNumber' },
    { text: "DOB", value: 'dob' },
    { text: "Age", value: 'age' },
    { text: "Gender", value: 'gender' },
    { text: "Height", value: 'height' },
    { text: "Weight", value: 'weight' },
    { text: "Emirates Id", value: 'emiratesdId' },
    { text: "EmiratesId Doc", value: 'emiratesIdDoc' },
    { text: "Insurance Doc", value: 'insuranceDoc' }
  ],
  DOCTOR: [
    { text: "Profile Image", value: 'profileImage' },
    { text: "Email", value: 'email' },
    { text: "First Name", value: 'firstName' },
    { text: "Last Name", value: 'lastName' },
    { text: "DOB", value: 'dob' },
    { text: "Mobile Number", value: 'mobileNumber' },
    { text: "Consultation Fee", value: 'consultationFee' },
    { text: "Years of Experience", value: 'yrsOfExp' },
    { text: "Speciality", value: 'specialityName' },
    { text: "Qualification", value: 'medEducationName' },
    { text: "Experience start date", value: 'experienceStartedFrom' },
  ],
  DELETE_MODAL: {
    TOTAL: "totalAppt",
    ROW1: "Number of upcoming appointments",
    ROW2: "Are you sure to delete your account?",
    BUTTON1: "Yes",
    BUTTON2: "Cancel",
    ROW3: "Enter Your password",
    BUTTON3: "Delete",
    ROW4: "Your account will be deleted after",
    ROW7: "days",
    BUTTON4: "OK",
    BUTTON5: "Deactivate",
    ROW5: "Deactivated Successfully!!",
    ROW6: "Your account has been deactivated for",
    ALERT: "Invalid password",
    ROW8: "Delete request has been sent",
    ROW9: "Login again to re-activate your account"
  },
  DELETE_REQUEST_STATUS: {
    0: 'Never Requested',
    1: 'Requested',
    2: 'is in Process',
    3: 'is under Review',
    4: 'Completed',
  },
  ABOUT_US: {
    TEXT1: "Reach us:",
    TEXT2: "support@tftus.com",
    TEXT3: "A Tele-Consult is a Tele call consultation with our doctors. Due to time & distance constraints, it may be difficult for you to physically travel to our centres. Tele-Consults allow you to speak to our doctors and seek appropriate medical care without the hassle of travelling in person.",
    TEXT4: "ADVANTAGES OF THE TELE CONSULT",
    TEXT5: "In this Tele-connected, highly mobile world at times personal scheduling of appointments can be challenging. And yet that shouldn't deprive you of the best medical care.",
    TEXT6: "Tele-Consult is an initiative to bring world-class health care service to the location & time that suits you best. You could be travelling or tied up with your schedule - wherever and whenever - simply log-in and have access to world-class medical care.",
    TEXT7: "With state-of-the-art technology & infrastructure, we make these Tele-interactions as real as possible for you. Thus, you have for yourself the best of both worlds. Tele-Consults help you overcome the challenges of distance & time. Additionally, they provide comfort & convenience - everything at the click of a button.",
    TEXT8: "HOW DOES THE TELE CONSULT WORK?"
  },
  BOX: {
    ICON_TEXT1: "Connect",
    ICON_TEXT2: "Confirm",
    ICON_TEXT3: "Consult",
    HEADING1: "Request for a Tele-Consult Appointment",
    HEADING2: "Receive Confirmation for Consultation",
    HEADING3: "Best Medical Care at the Click of a Button",
    TEXT1: "Book a slot with a doctor of a specific speciality.This helps us understand your exact need and enables us to assist you accordingly.Pay the ammount(online or by cash).",
    TEXT2: "Once RCMexecutive update your payment, you will receive payment update notification if paid online.Once payment is completed, you will receive appointment confirmation notification.",
    TEXT3: "Consult with our experienced and expert team of doctors via phone from the comfort of your own location. With our expert panel of doctors, you'll receive the best advice without having to step out of your home."
  }
}
