import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Loader from "./views/Loader/loader";
import store from "./store";
import { Provider } from "react-redux";
import firebase from './config';
import { registerServiceWorker } from "./serviceWorker";

loadRemoteVidyoClientLib(false, false);

function loadRemoteVidyoClientLib(useNativeWebRTC = false, plugin = false) {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://static.vidyo.io/latest/javascript/VidyoClient/VidyoClient.js?onload=onVidyoClientLoaded&useNativeWebRTC=${useNativeWebRTC}&plugin=${plugin}&webrtcLogLevel=info`;
  document.getElementsByTagName('head')[0].appendChild(script);
}
ReactDOM.render(
  <Provider store={store}>
    <Loader />
    <App />
  </Provider>, document.getElementById('root')
);

registerServiceWorker();
