import {
  START_REQUEST,
  FINISH_REQUEST,
  SAVE_APPOINTMENT,
  RECEIVE_ERROR,
  PATIENT_DETAILS,
  GET_PATIENT_UPCOMING_APPOINTMENTS,
  GET_PATIENT_HISTORY_APPOINTMENTS,
  LANGUAGE,
  SET_DELETE_STATUS
} from "../actions/types";

const initialState = {
  profileDetails: {},
  appointmentData: {},
  totalUpcomingAppointments: "",
  totalHistoryAppointments: "",
  totalActiveAppointments: "",
  isFetching: false,
  isError: false,
  Error: "",
  upcomingAppointments: [],
  historyAppointments: [],
  language: "",
  isDeleteRequested: 0
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        Error: ""
      });
    case PATIENT_DETAILS:
      return Object.assign({}, state, {
        profileDetails: action.data.detailResult,
        isDeleteRequested: action.data.deleteRequestStatus.status,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case SAVE_APPOINTMENT:
      return Object.assign({}, state, {
        appointmentData: action.data,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case SET_DELETE_STATUS:
      return Object.assign({}, state, {
        isDeleteRequested: action.data.status
      });
    case GET_PATIENT_UPCOMING_APPOINTMENTS:
      return Object.assign({}, state, {
        upcomingAppointments: action.data.data,
        totalUpcomingAppointments: action.data.total,
        totalActiveAppointments: action.data.totalActiveCount,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case GET_PATIENT_HISTORY_APPOINTMENTS:
      return Object.assign({}, state, {
        historyAppointments: action.data.data,
        totalHistoryAppointments: action.data.total,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case LANGUAGE:
      return Object.assign({}, state, {
        language: action.data,
        isError: false,
        isFetching: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        Error: action.data,
        isFetching: false,
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default patientReducer;
