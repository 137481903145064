import firebase from 'firebase/app';
import 'firebase/messaging';
import swal from "sweetalert";
// import { createBrowserHistory } from 'history';
import { Navigate } from "react-router-dom";

// const history = createBrowserHistory({ forceRefresh: true });
const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseUrl,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
messaging.usePublicVapidKey(process.env.REACT_APP_publicKey);

export const requestPermission = (saveFcmToken) => {
    messaging.requestPermission()
        .then(function () {
            return messaging.getToken()
        }).then(function (token) {
            localStorage.setItem("fcm", token);
            const body = {
                userId: JSON.parse(localStorage.getItem('user')).id,
                fcmToken: token,
                platform: "web"
            };
            return saveFcmToken(body);
        }).catch(function (err) {
            console.log(err.message);
        });
}

const displayAlert = (data) => {
    const { title, body, appointmentId, type } = data;
    let buttons;
    if (type === "appointment-update")
        buttons = {
            view: {
                text: "View Details",
                value: "view details",
                className: "btn btn-success mr-1"
            },
            ok: {
                text: "Ok",
                value: "ok",
                className: "btn btn-info mr-1"

            }
        }

    else if (type === "call-disconnected")
        buttons = {
            ok: {
                text: "Ok",
                value: "ok1"
            }
        }
    else
        buttons = {
            join: {
                text: "join",
                value: "join"
            },
        }

    swal(title, body, { buttons: buttons })
        .then((value) => {
            switch (value) {
                case "view details": Navigate(`/appointments/${appointmentId}`);
                case "ok": Navigate(window.location.pathname);
                case "join": Navigate(`/appointments/${appointmentId}`);
            }
        })
}

messaging.onMessage(payload => {
    displayAlert(payload.data)
});

export default firebase;
