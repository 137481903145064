import axios from "axios";
import { tokenExpireError, tokenExpire } from "./global";

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return tokenExpire(error)
  }
);
class Api {
  static get headers() {
    return {
      Authorization: `bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
      'lang': localStorage.getItem('lang')
    };
  }

  static async get(route, params) {
    return Api.xhr({ route, params, method: "GET" });
  }

  static put(route, body, isFormtype, params) {
    return Api.xhr({ route, params, body, method: "PUT", isFormtype });
  }

  static async postUser(route, body, params) {
    return axios.post(route, body, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  static async putUser(route, body, params) {
    return axios.put(route, body, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
    });
  }


  static async post(route, body, isFormtype, params) {
    return Api.xhr({ route, params, body, method: "POST", isFormtype });
  }

  static delete(route, body, params) {
    return Api.xhr({ route, body, params, method: "DELETE" });
  }

  static async xhr({ route, params, body, method, isFormtype }) {
    const options = { method };
    if (params) {
      const queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      route += "?" + queryString;
    }

    if (isFormtype) {
      options.headers = {
        "content-type": "multipart/form-data",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      };
      if (body) {
        options.body = body;
      }
    } else {
      options.headers = Api.headers;
      if (body) {
        options.body = JSON.stringify(body);
      }
    }
    const response = await fetch(route, options);
    if (response.status === 401) {
      tokenExpireError();
    }
    else {
      return response.json();
    }
  }
}

export default Api;
