import { receiveError, saveData } from "./index";
import { API } from "../utils/apiPath";
import Api from "../utils/api";
import { ACTIVE_BRAND, GET_CONFIGURATION } from "./types";

export const getUserConfigurations = () => {
  return async (dispatch, getState) => {
    try {
      const response = await Api.get(
        `${API.BACKEND}/${API.VERSION}/${API.GET_USER_CONFIGURATION}`
      );
      response && dispatch(saveData(response.successData, GET_CONFIGURATION));
    } catch (err) {
      dispatch(receiveError(err));
    }
  };
};

export const getBrand = () => {
  return async (dispatch, getState) => {
    try {
      const response = await Api.get(
        `${API.BACKEND}/${API.VERSION}/${API.GET_BRAND}`
      );
      response && dispatch(saveData(response.successData, ACTIVE_BRAND));
    } catch (err) {
      dispatch(receiveError(err));
    }
  };
};
