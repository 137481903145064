export const API = {
  BACKEND: process.env.REACT_APP_backend,
  VERSION: "v1",
  LOGIN_USER: "auth/login",
  DEACTIVATE_USER: "users/deactivate-account",
  GENERATE_OTP: "auth/otp",
  FORGET_PASSWORD: "auth/forgot-password",
  RESET_PASSWORD: "auth/reset-password",
  SIGNUP_USER: "auth/signup",
  TERMS_CONDITIONS: "brand/get-terms-conditions",
  APPROVE_TC: "users/approve-terms-conditions",
  DIALCODES: "brand/get-countries",
  SAVE_FCM_TOKENS: "firebase/register-notifications",
  CLEAR_FCM_TOKENS: "auth/register-notifications",

  GET_UPCOMING_APPOINTMENTS: "doctor/get-upcomming-appointments",
  GET_HISTORY_APPOINTMENTS: "doctor/get-history-appointments",
  GET_PATIENT_UPCOMING_APPOINTMENTS: "patient/get-upcomming-appointments",
  GET_PATIENT_HISTORY_APPOINTMENTS: "patient/get-appointments-history",
  GET_DOCTOR_INFO: "doctor/get-info",
  GET_APPOINTMENT_DETAIL: "doctor/get-appointment-details",
  GET_PATIENT_DETAIL: "patient/get-info",
  UPDATE_PATIENT_DETAIL: "patient/update-info",
  GET_PATIENT_APPOINTMENT: "patient/get-appointment-details",
  GET_SPECIALTIES_LIST: "admin/specialities-list",
  UPDATE_SPECIALITIES: "admin/activate-specialities",
  UPDATE_VITALS: "patient/update-appointment-vital",
  GET_USER_CONFIGURATION: "users/configuration",
  GET_NOTIFICATIONS: "users/notification?page=1&pageSize=50",
  MARK_READ_NOTIFICATION: "users/notification/mark-read",
  ADD_NOTES: "doctor//notes",
  ADD_DOCS: "doctor/appt-docs",
  GET_SPECIALTIES: "users/get-specialties",
  GET_USER_DOCTOR: "users/get-doctors",
  GET_DOCTOR_PROFILE: "patient/get-doctor-detail",
  GET_DOCTOR_SLOTS: "patient/get-doctor-slots",
  BOOK_DOCTOR_SLOTS: "patient/book-appointment-slot",
  PAYMENT_INITIATE: "payment/initiate",
  CONFIRM_PAYMENT: "payment/confirm",
  VERIFY_PAYMENT: "payment/verify",
  TRANSACTION_STATUS: "payment/status/:apptId",
  MY_DOCTORS: "patient/get-my-doctors",
  PATIENT_APPT_DOCS: "patient/appt-docs",
  ADD_FEEDBACK: "users/feedback",
  UPDATE_CALL_START_END_TIME: "users/update-call-time",
  GET_BRAND: 'brand/get-brand',
  SETTING: "users/settings",
  DELETE_USER: "patient/delete-user-data",
  GET_USER_STATUS: "patient/delete-user-data-status",
  ADD_PRESCRIPTION: "doctor/prescription"
};
