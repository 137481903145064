import {RECEIVE_ERROR} from './types'

/**
 * Action Creators
 */

export const startRequest = (type) => {
  return {
    type
  };
};

export const saveData = (data,type) => {
  return {
    type: type,
    data: data
  };
};

export const receiveError = (error) => {
  return {
    type: RECEIVE_ERROR,
    data: error
  };
};


