import {
  START_REQUEST,
  FINISH_REQUEST,
  RECEIVE_ERROR,
  GET_ALL_NOTIFICATIONS,
} from "../actions/types";

const initialState = {
  totalNotifications: "",
  isFetching: false,
  isError: false,
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });
    case GET_ALL_NOTIFICATIONS:
      return Object.assign({}, state, {
        notifications: action.data.data,
        totalNotifications: action.data.total,
        unreadCount: action.data.unreadCount,
        isFetching: false,
        isError: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default notificationReducer;
