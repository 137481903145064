import store from "../store";
import { startRequest, saveData, receiveError } from "./index";
import { API } from "../utils/apiPath";
import Api from "../utils/api";
import { START_REQUEST, FINISH_REQUEST, DIAL_CODES, TNC, SET_DELETE_STATUS } from "./types";

export const login = (user) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch, getState) => {
    try {
      const response = await Api.post(`${API.BACKEND}/${API.LOGIN_USER}`, user);
      if (response.successData) {
        localStorage.setItem("token", response.successData.accessToken);
        localStorage.setItem("user", JSON.stringify(response.successData.user));
      }
      dispatch(startRequest(FINISH_REQUEST));
      return response;
    } catch (err) {
      return dispatch(receiveError(err));
    }
  };
};

export const generateOTP = (data) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch, getState) => {
    try {
      const response = await Api.post(
        `${API.BACKEND}/${API.GENERATE_OTP}`,
        data
      );
      dispatch(startRequest(FINISH_REQUEST));
      return response;
    } catch (error) {
      dispatch(receiveError(error));
    }
  };
};
export const signup = (data) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch, getState) => {
    try {
      const response = await Api.postUser(`${API.BACKEND}/${API.SIGNUP_USER}`, data);
      if (response.data.successData) {
        localStorage.setItem("token", response.data.successData.accessToken);
        localStorage.setItem("user", JSON.stringify(response.data.successData.user));
        dispatch(startRequest(FINISH_REQUEST));
        return response.data;
      } else {
        dispatch(receiveError(response.message));
        return response.data;
      }
    }
    catch (error) {
      dispatch(receiveError(error.message));
      return error.response.data;
    }
  }
}
export const getTermsandPolicy = () => {
  return async (dispatch, getState) => {
    try {
      const response = await Api.get(`${API.BACKEND}/${API.VERSION}/${API.TERMS_CONDITIONS}`);
      if (response.successData) {
        dispatch(saveData(response.successData, TNC));
      }
      else
        dispatch(receiveError(response.message));
    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }
}

export const approveTermsandPolicy = (id) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch, getState) => {
    try {
      const response = await Api.put(`${API.BACKEND}/${API.VERSION}/${API.APPROVE_TC}/${id}`);
      dispatch(startRequest(FINISH_REQUEST));
      return response;
    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }
}

export const forgetPassword = (data) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch, getState) => {
    try {
      const response = await Api.post(`${API.BACKEND}/${API.FORGET_PASSWORD}`, data);
      dispatch(startRequest(FINISH_REQUEST));
      return response;
    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }

}

export const resetPassword = (data) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch, getState) => {
    try {
      const response = await Api.post(`${API.BACKEND}/${API.RESET_PASSWORD}`, data);
      dispatch(startRequest(FINISH_REQUEST));
      return response;

    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }

}

export const getDialCodes = () => {
  return async (dispatch) => {
    try {
      const response = await Api.get(`${API.BACKEND}/${API.VERSION}/${API.DIALCODES}`);
      if (response.successData) {
        dispatch(saveData(response.successData, DIAL_CODES));
      }
      else
        dispatch(receiveError(response.message));
    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }
}

export const saveFcmToken = (body) => {
  return async (dispatch, getState) => {
    try {
      const response = await Api.post(`${API.BACKEND}/${API.VERSION}/${API.SAVE_FCM_TOKENS}`,
        body);
      return response;
    }
    catch (error) {
      dispatch(receiveError(error.message))
    }
  }
}
export const clearFcmToken = (body) => {
  return async (dispatch, getState) => {
    try {
      const response = await Api.delete(`${API.BACKEND}/${API.CLEAR_FCM_TOKENS}`,
        body);
      return response;
    }
    catch (error) {
      dispatch(receiveError(error.message))
    }
  }
}
export const deactivateAccount = (body) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch) => {
    try {
      const response = await Api.post(`${API.BACKEND}/${API.VERSION}/${API.DEACTIVATE_USER}`, body);
      dispatch(startRequest(FINISH_REQUEST));
      return response;
    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }
}

export const deleteAccountDetails = (body) => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch) => {
    try {
      const response = await Api.post(`${API.BACKEND}/${API.VERSION}/${API.DELETE_USER}`, body);
      dispatch(startRequest(FINISH_REQUEST));
      return response;
    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }
}

export const getDeleteRequestStatus = () => {
  store.dispatch(startRequest(START_REQUEST));
  return async (dispatch) => {
    try {
      const response = await Api.get(`${API.BACKEND}/${API.VERSION}/${API.GET_USER_STATUS}`);
      response && response.successData && dispatch(saveData(response.successData, SET_DELETE_STATUS));
      dispatch(startRequest(FINISH_REQUEST));
    }
    catch (error) {
      dispatch(receiveError(error.message));
    }
  }
}