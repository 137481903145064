import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { useSelector, useDispatch } from "react-redux";
import { routePath } from "./constants/routes";
import { parseJwt, logout } from "./utils/global";
import { getBrand } from './actions/configurations'
import firebase from "./config";
import { Language } from "./constants/strings";
// import { render } from "react-dom";
// import { createBrowserHistory } from "history";

// const history = new createBrowserHistory({ forceRefresh: true });

// window.addEventListener('focus', () => {
//   var DBOpenRequest = window.indexedDB.open("webappDb", 3);
//   DBOpenRequest.onsuccess = function (event) {
//     var db = event.target.result;
//     var transaction = db.transaction(["webapp1"], "readwrite");
//     var objectStore = transaction.objectStore("webapp1");
//     var objectStoreRequest = objectStore.get(1);
//     objectStoreRequest.onsuccess = function (event) {
//       var myRecord = objectStoreRequest.result;
//       if (myRecord.newNotif) {
//         objectStore.put({ newNotif: false, myid: 1 })
//         history.push(window.location.href);
//       }
//     }
//   }
// });

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/Pages/Login"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Register = React.lazy(() => import("./views/Pages/Register/Register"));
const ResetPassword = React.lazy(() =>
  import("./views/Pages/ResetPassword/ResetPassword")
);

const App = () => {
  const dispatch = useDispatch();
  const { brandData } = useSelector(state => state.Configurations);
  const { language } = useSelector(state => state.Login);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const isTokenExpire = parseJwt(token);
      isTokenExpire && logout();
    }
    Object.keys(brandData).length === 0 && dispatch(getBrand());
    const lang = localStorage.getItem("lang");
    Language.setLanguage(lang ? lang : "en");
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Routes>
          <Route
            path={routePath.LOGIN}
            name="Login Page"
            element={<Login/>}
          />
          <Route
            path={routePath.REGISTER}
            name="Signup Page"
            element={<Register />}
          />
          <Route
            path={routePath.RESET_PASSWORD}
            name="Reset password"
            element={<ResetPassword />}
          />
          <Route
            path="/*"
            name="Home"
            element={<DefaultLayout />}
          />
          <Route
            path="*"
            name="Page 404"
            element={<Page404 />}
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
