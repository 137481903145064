import {
  START_REQUEST,
  FINISH_REQUEST,
  SAVE_APPOINTMENT,
  RECEIVE_ERROR,
  GET_ALL_APPOINTMENTS,
  GET_HISTORY_APPOINTMENTS,
  LANGUAGE,
} from "../actions/types";

const initialState = {
  appointmentData: {},
  totalUpcomingAppointments: "",
  totalHistoryAppointments: "",
  isFetching: false,
  isError: false,
  Error: "",
  upcomingAppointments: [],
  historyAppointments: [],
  language: ''
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });
    case SAVE_APPOINTMENT:
      return Object.assign({}, state, {
        appointmentData: action.data,
        isFetching: false,
        isError: false,
        Error: "",
      });
    case GET_ALL_APPOINTMENTS:
      return Object.assign({}, state, {
        upcomingAppointments: action.data.data,
        totalUpcomingAppointments: action.data.total,
        isFetching: false,
        isError: false,
        Error: "",
      });
    case GET_HISTORY_APPOINTMENTS:
      return Object.assign({}, state, {
        historyAppointments: action.data.data,
        totalHistoryAppointments: action.data.total,
        isFetching: false,
        isError: false,
        Error: "",
      });
    case LANGUAGE:
      return Object.assign({}, state, {
        language: action.data,
        isError: false,
        isFetching: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        Error: action.data,
        isFetching: false,
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default appointmentReducer;
