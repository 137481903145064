import { routePath } from "./constants/routes";

export const NAVIGATION = {
  Doctor: {
    items: [
      {
        name: "Appointments",
        url: routePath.APPOINTMENTS,
        icon: "icon-star",
      },
    ],
  },
  Patient: {
    items: [
        {
          name: "Specialities", 
          url: routePath.SPECIALITIES,
          icon: "icon-star",
        }, 
    ],
  },
};
