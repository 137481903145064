import { useEffect, useState } from "react";
import store from "../store";
import { LOGOUT } from "../actions/types";
import { startRequest, receiveError } from "../actions/index";
import { clearFcmToken } from "../actions/login";
import moment from "moment";
import { Language } from "../constants/strings";
import { NAVIGATION } from "../_nav";
import { PAGE_SIZE, VIDEO_CALL_URL } from "../constants/globalConstants";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}


export const logout = async () => {
  const body = {
    userId: JSON.parse(localStorage.getItem('user')).id,
    fcmToken: localStorage.getItem('fcm'),
    platform: "web"
  };
  const response = await store.dispatch(clearFcmToken(body));
  if (response && response.successData) {
    const lang = localStorage.getItem("lang");
    localStorage.clear();
    localStorage.setItem("lang", lang);
    store.dispatch(startRequest(LOGOUT));
  }
};

export const tokenExpireError = async () => {
  await logout();
  store.dispatch(receiveError(Language.strings.TOKEN_EXPIRE));
};

export const tokenExpire = (error) => {
  const {
    response,
  } = error;
  if (response) {
    if (response.status === 401) {
      tokenExpireError();
    }
    else {
      return Promise.reject(error);
    }
  }
}

export const getUserRole = () => {
  if (localStorage.getItem("user")) {
    return Language.UserRole[JSON.parse(localStorage.getItem("user")).role];
  }
};

export const imageDownload = () => {
  return window.addEventListener('contextmenu', event => {
    if (event.target.nodeName === "IMG") {
      event.preventDefault();
    }
  }, false);
}

export const convertDate = (date) => moment(date).format("hh:mm A DD-MM-YYYY");

export const getRedirectLink = () =>
  getUserRole() && NAVIGATION[getUserRole()].items[0].url;

export const getUrlPagination = (pageNo) =>
  `?page=${pageNo}&pageSize=${PAGE_SIZE}`;

export const converTimeToHourFormat = (time) => moment(time).format("hh:mm A");

export const convertToDate = (time, format) => moment(time).format(format);

export const checkPastDate = (date1, date2) =>
  new Date(date1) <= new Date(date2);

export const checkFutureDate = (date1, date2) =>
  new Date(date1) >= new Date(date2);


export const getAgeByDOB = (dob) => moment().diff(dob, "years");

export const calculateExperience = (date) =>
  new Date().getFullYear() - new Date(date).getFullYear();

export const disappear = (toggle) => {
  setTimeout(toggle, 8000);
};

export const objectToQuery = (params) =>
  Object.keys(params)
    .map((key) => {
      return params[key] && key + "=" + params[key];
    })
    .join("&");

export const dateDifference = (date) => {
  const daysDiff = moment().diff(date, "days");
  return daysDiff === 0
    ? "Today"
    : daysDiff === 1
      ? "Yesterday"
      : `${daysDiff} days ago`;
};

export const dayHourMinute = (time) => {
  const date = moment().add(time, 'minutes')
  const days = date.diff(moment().format(), "days");
  const hour = date.diff(moment().format(), "hours") % 24;
  const min = date.diff(moment().format(), "minutes") % 60;
  return `${!!days ? `${days} days` : ""} ${!!hour ? `${hour} hours` : ""} ${!!min ? `${min} minutes` : ""}`
}

export const getCallLink = (appntID, token) =>
  `${VIDEO_CALL_URL}/${appntID}/Tele-consulting?jwt=${token}`;

export const getFileType = (filename) => {
  const acceptedImageTypes = ["gif", "jpeg", "png", "jpg"];
  return filename && acceptedImageTypes.includes(filename.split(".").pop());
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const parsedToken = JSON.parse(jsonPayload);
  return checkJWTExpire(parsedToken.exp * 1000);
};

export const checkJWTExpire = (time) => time < new Date().getTime();

export const getImagePath = (imageName) => `${process.env.REACT_APP_backend}/${imageName}`;

