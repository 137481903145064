import { START_REQUEST, RECEIVE_ERROR, LOGIN, FINISH_REQUEST, TNC, DIAL_CODES, LANGUAGE } from "../actions/types";

const initialState = {
  tc: {},
  country: [],
  isFetching: false,
  isError: false,
  error: "",
  language: ""
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });
    case LOGIN:
      return Object.assign({}, state, {
        error: "",
        isFetching: false,
        isError: false,
      });
    case TNC:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        tc: action.data
      });
    case DIAL_CODES:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        country: action.data
      });
    case LANGUAGE:
      return Object.assign({}, state, {
        language: action.data,
        isError: false,
        isFetching: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        error: action.data,
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default loginReducer;
