import {
  START_REQUEST,
  RECEIVE_ERROR,
  GET_USER_DOCTOR,
  GET_DOCTOR_PROFILE,
  GET_DOCTOR_SLOTS,
  BOOK_DOCTOR_SLOTS,
  FINISH_REQUEST,
  DOCTOR_DETAILS,
  MY_DOCTORS,
  LANGUAGE
} from "../actions/types";

const initialState = {
  doctorData: {},
  doctor: [],
  isFetching: false,
  isError: false,
  doctorProfile: [],
  doctorSlots: [],
  vitals: [],
  profileDetails: {},
  myDoctors: [],
  language: ""
};

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });
    case GET_USER_DOCTOR:
      return Object.assign({}, state, {
        doctor: action.data.data,
        isFetching: false,
        isError: false,
      });
    case GET_DOCTOR_PROFILE:
      return Object.assign({}, state, {
        doctorProfile: action.data,
        isFetching: false,
        isError: false,
      });
    case DOCTOR_DETAILS:
      return Object.assign({}, state, {
        profileDetails: action.data,
        isFetching: false,
        isError: false,
      });
    case MY_DOCTORS:
      return Object.assign({}, state, {
        myDoctors: action.data.data,
        numMyDoctors: action.data.total,
        isFetching: false,
        isError: false,
      });
    case GET_DOCTOR_SLOTS:
      return Object.assign({}, state, {
        doctorSlots: action.data,
        isFetching: false,
        isError: false,
      });
    case BOOK_DOCTOR_SLOTS:
      return Object.assign({}, state, {
        vitals: action.data,
        isFetching: false,
        isError: false,
      });
    case LANGUAGE:
      return Object.assign({}, state, {
        language: action.data,
        isError: false,
        isFetching: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default doctorReducer;
