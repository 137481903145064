import { combineReducers } from "redux";
import appointmentReducer from "./appointmentReducer";
import configurationReducer from "./configurationReducer";
import notificationReducer from "./notificationReducer";
import loginReducer from "./loginReducer";
import specialityReducer from "./specialityReducer";
import doctorReducer from "./doctorReducer";
import patientReducer from "./patientReducer";
import { LOGOUT } from "../actions/types";

const allReducers = combineReducers({
  Appointments: appointmentReducer,
  Configurations: configurationReducer,
  Notifications: notificationReducer,
  Login: loginReducer,
  Speciality: specialityReducer,
  Doctors: doctorReducer,
  Patients: patientReducer,
});

const Reducer = (state, action) => {

  if (action.type === LOGOUT)
    state = undefined;

  return allReducers(state, action);
}
export default Reducer;
