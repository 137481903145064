import {
  START_REQUEST,
  RECEIVE_ERROR,
  GET_SPECIALITIES_LIST,
  GET_SPECIALTIES,
  FINISH_REQUEST,
} from "../actions/types";

const initialState = {
  brandingData: {},
  isFetching: false,
  isError: false,
  specialities: [],
  allSpecialities: [],
  error: "",
};

const specialityReducer = (state = initialState, action) => {

  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });
    case GET_SPECIALTIES:
      return Object.assign({}, state, {
        allSpecialities: action.data.data,
        isFetching: false,
        isError: false,
      });
    case GET_SPECIALITIES_LIST:
      return Object.assign({}, state, {
        specialities: action.data.data,
        isFetching: false,
        isError: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        error: ""
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default specialityReducer;
