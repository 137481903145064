export const ar = {
  placeholder: {
    TEXT1: "عما تبحث؟",
    TEXT2: "الاسم الاول",
    TEXT3: "الكنية",
    TEXT4: "رقم الهاتف المحمول",
    TEXT5: "Otp",
    TEXT6: "أضف تعليق",
  },
  notFound: {
    CODE: "أربعة مائة و أربعة",
    TEXT1: "وجه الفتاة! أنت ضائع.",
    TEXT2: "لم يتم العثور على الصفحة التي تبحث عنها.",
    SEARCH: "بحث"
  },
  BANNER: {
    COOKIE_BANNER_TEXT: "يستخدم هذا الموقع ملفات تعريف الارتباط لضمان حصولك على أفضل تجربة على موقعنا.",
    COOKIE_POLICY: "اتفاقية ملفات تعريف الارتباط.",
    COOKIE_BTN: "فهمتك",
  },
  alerts: {
    LOADING: "جار التحميل...",
    ARE_YOU_SURE: "هل أنت واثق؟",
    YES: "نعم",
    CANCEL: "إلغاء",
    NO_NOTIFS: "لا إخطارات جديدة حتى الآن",
    RATINGS: "التقييمات",
    SELECT: "تحديد",
    RCM_INACTIVITY: "منتهية الصلاحية بسبب عدم نشاط Rcm",
    NON_PAYMENT: "منتهية الصلاحية بسبب عدم الدفع",
    ALERT1: "تمت إضافة الملاحظات بنجاح",
    ALERT2: "من فضلك اكتب شيا",
    ALERT3: "تم تحميل الصورة بنجاح !!",
    ALERT4: "تنسيق غير صالح !!",
    ALERT5: "تم تحميل ملف PDF بنجاح !",
    ALERT6: "خطأ في تقديم المستند",
    ALERT7: "تم تأكيد الدفع الخاص بك"
  },
  footer: {
    TEXT1: "مشغل بواسطة",
    TEXT2: "استشارات تيلي",
  },
  AppointmentTabs: {
    UPCOMING_TAB: "المواعيد القادمة",
    HISTORY_TAB: "تعيينات التاريخ",
    NO_DATA: "لا توجد بيانات لإظهارها",
    EDIT: "تعديل",
    VIEW: "رأي",
    PAY: "دفع",
    DOCTORS: "الأطباء",
    MY_DOCTORS: "أطبائي"
  },
  strings: {
    DASHBOARD: "لوحة القيادة",
    PROFILE: "الملف الشخصي",
    LOGOUT: "تسجيل خروج",
    HEADER_TEXT: "تيلي للاستشارات ويب",
    SIGNIN_TEXT: "تسجيل الدخول إلى حسابك",
    LOGIN_TEXT: "تسجيل الدخول",
    OTP_TEXT: "إنشاء كلمة مرور لمرة واحدة",
    CONNECT: "الاتصال",
    ALERT: "يجب أن يكون رقم الجوال 10 أرقام",
    ALERT1: "تم التحديث بنجاح",
    ALERT2: "لم لا تتطابق مع كلمة المرور",
    ALERT3: "أدخل البريد الإلكتروني أو رقم الهاتف المحمول",
    SIGNUP_TEXT1: " لا تملك حساب؟",
    REGISTER_TEXT: "تسجيل",
    CREATE_TEXT: "أنشئ حسابك",
    LOGIN_TEXT1: "هل لديك حساب ؟",
    NEXT: "التالى",
    CREATE: "إنشاء حساب",
    TC1: "الأحكام والشروط",
    TC2: "يرجى قبول الشروط والأحكام",
    TC3: "tcId",
    TC4: "تم قبوله",
    TC5: "يرجى قبول أحدث الشروط والأحكام",
    TELECONSULTING: "تيلي للاستشارات",
    AGREE: "أنا أوافق على",
    DIAL_CODE: "الاتصال الهاتفي",
    INSURANCE_PHOTO1: "يرجى تحميل صورة التأمين",
    INSURANCE_PHOTO2: "صور التأمين",
    EMIRATES_TEXT1: "يرجى تحميل بطاقة الهوية الإماراتية",
    EMIRATES_TEXT2: "بطاقة الهوية الإماراتية",
    EMIRATES_TEXT3: "هويه الإمارات",
    EMIRATES_TEXT4: "هويه الإمارات",
    MOBILE_TEXT: "رقم الهاتف المحمول",
    OTP: "otp",
    PASSWORD: "password",
    TEXT: "نص",
    CHANGE_TEXT: "يتغيرون",
    SAVE_TEXT: "حفظ",
    DOCTOR_ROLE: "دور المستخدم",
    FORGOT_PASSWORD: "هل نسيت كلمة المرور ؟",
    NO_NOTIFICATION: "لا يوجد إشعار جديد",
    RESET_PASSWORD: "إعادة تعيين كلمة المرور",
    SUCCESS: "نجاح",
    SHOW_PASSWORD: "عرض كلمة المرور",
    KEY: "مفتاح",
    BUTTON1: "حسنا",
    BUTTON2: "احصل على OTP على الهاتف / البريد الإلكتروني",
    BUTTON3: "إعادة تعيين",
    BUTTON4: "قبول",
    BUTTON5: "عرض TC",
    BUTTON6: "ليس الان",
    BUTTON7: "إرسال",
    MODAL1: "تمت إعادة تعيين كلمة المرور بنجاح !!",
    LABEL1: "بريد الكتروني او هاتف",
    LABEL2: "OTP",
    LABEL3: "كلمه السر",
    LABEL4: "تأكيد كلمة المرور",
    LABEL5: "رمز الطلب",
    LOGIN_TYPE1: "تسجيل الدخول مع OTP؟",
    LOGIN_TYPE2: "تسجيل الدخول بكلمة المرور؟",
    MODAL_HEADER: " تقييم جودة مكالمة الفيديو الخاصة بك",
    COMMENT: "تعليق",
    MODAL_BODY: "شكرا على التقييمات",
    CANCEL: "إلغاء",
    EMAIL_MOBILE: "البريد الإلكتروني أو الجوال",
    DOWNLOAD: "تفاصيل التحميل",
    PDF1: "تحميل المستند ...",
    PDF2: "تحميل PDF",
    PDF3: "DETAILS",
    DELETE_ACCOUNT: "حذف الحساب",
    DEACTIVATE_ACCOUNT: "تعطيل الحساب",
    ADD: "أضف",
    ALERT4: "تم تعطيل حسابك ، يمكنك تسجيل الدخول بعد ذلك",
    ALERT5: "أيام",
    BACK: "العودة إلى المعلومات الشخصية",
    ABOUT: "معلومات عنا",
    TOKEN_EXPIRE: "انتهت صلاحية الرمز !!"
  },
  BadgeColor: {
    "قيد الانتظار": "warning",
    "تم تأكيد": "success",
    "منتهية الصلاحية": "danger",
    فشل: "danger",
    ألغيت: "danger",
    WatingForPayment: "secondary",
    مرفوض: "danger",
    "منجز": "success",
    "لا يوجد": "secondary",
    payNow: "danger",
    Start: "primary",
  },
  ButtonColor: {
    "قيد الانتظار": "warning",
    "تم تأكيد": "success",
    "منتهية الصلاحية": "danger",
    فشل: "danger",
    ألغيت: "danger",
    WatingForPayment: "secondary",
    مرفوض: "danger",
    "منجز": "success",
    "لا يوجد": "secondary",
    PayNow: "success",
    Start: "primary",
  },
  ValidationMessages: {
    EMAIL_MOBILE: "رقم الهاتف المحمول",
    INVALID_EM: "البريد الإلكتروني أو رقم الجوال غير صالح",
    OTP1: "الرجاء إدخال Otp",
    OTP2: "لا يمكن أن يكون otp سلبيًا",
    OTP3: "OTP غير صالح",
    OTP4: "يجب أن يتكون Otp من 4 أرقام",
    PASSWORD1: "يرجى إدخال كلمة المرور",
    PASSWORD2: "يجب أن تحتوي كلمة المرور على ثمانية أحرف على الأقل",
    PASSWORD3: "أعد إدخال كلمة المرور",
    FIRSTNAME: "الرجاء إدخال الاسم الأول",
    FIRSTNAME2: "الاسم الأول غير صالح",
    LASTNAME1: "يرجى إدخال اسم العائلة",
    LASTNAME2: "اسم العائلة غير صالح",
    MOBILE_NO1: "يرجى إدخال رقم الهاتف المحمول",
    MOBILE_NO2: "رقم الموبايل. لا يمكن أن تكون سلبية",
    MOBILE_NO3: "يجب أن يتكون رقم الهاتف المحمول من 10 أرقام",
    IMAGE: "تنسيق ملف غير صالح",
    EMAIL: "بريد إلكتروني خاطئ",
    HEIGHT1: "لا يمكن أن يكون الارتفاع سالبًا",
    HEIGHT2: "لا يمكن أن يكون الارتفاع أكثر من 300 سم",
    HEIGHT3: "ارتفاع غير صالح",
    WEIGHT1: "لا يمكن أن يكون الوزن سالبًا",
    WEIGHT2: "لا يمكن أن يكون الوزن أكثر من 200 كجم",
    WEIGHT3: "الوزن غير صالح",
    EMIRATES1: "لا يمكن أن تكون سالبة",
    EMIRATES2: "يجب أن يتراوح طوله بين 5 إلى 20 رقمًا",
    DIAL_CODE: "يرجى تحديد رمز الاتصال",
    BP: "BP غير صالح",
    TEMPERATURE: "درجة حرارة غير صالحة",
    TEMP_UNIT: "حدد وحدة درجة الحرارة"
  },
  Vitalstrings: {
    UNIT: "mmHg",
    FORMAT: "DD-MM-YYYY",
    APPOINTMENT_DATE: "تاريخ الموعد",
    APPOINTMENT_TIME: "وقت الموعد",
    VITAL_HEADER: "الأعضاء الحيوية للمريض",
    TEXT1: "تم إرسال طلبك للموافقة",
    TEXT2: "املأ التفاصيل (اختياري)",
    LABEL1: "ضغط الدم",
    LABEL2: "درجة الحرارة",
    LABEL3: "درجة الحرارة وحدة",
    LABEL4: "وقت التسجيل",
    BUTTON: "حفظ",
    INVALID: "تنسيق غير صالح",
    NAME1: "ضغط الدم",
    NAME2: "درجة الحرارة",
    SELECT: "تحديد",
    UPLOAD: "رفع",
    CREATE_PRESCRIPTION:"إنشاء وصفة طبية"
  },
  notesStrings: {
    NOTES_HEADER: "ملاحظات",
    NOTES_ERR: "لم تتم إضافة ملاحظات بعد",
    SEND: "إرسال",
    ADD_NOTES: "أضف ملاحظات",
    NOTES_ERR1: "لا توجد ملاحظات مضافة",

  },
  docsStrings: {
    DOCS_HEADER: "مستندات",
    VIEW_PDF: " عرض ملف PDF",
    NO_DOCS_ERROR: "لم تتم مشاركة مستندات حتى الآن",
    NO_DOCS_ERROR1: "لا يوجد مستند مشترك"
  },
  doctorStrings: {
    FORMAT: "YYYY-MM-DD",
    TEXT1: "لا توجد فتحات للعرض",
    BUTTON1: "تحديد موعد",
    LABEL1: "تاريخ التحول",
    ALERT1: "حدد وقت الفتحة",
    ID: ":apptid",
    NAME: "اسم",
    SPECIALITY: "متخصص في",
    TOTAL_APPTS: "إجمالي المواعيد",
    EXPERIENCE: "تجربة",
    VIEW_PROFILE: "عرض الصفحة الشخصية",
    SELECT_DATE: "حدد التاريخ والوقت"

  },
  AppointmentStrings: {
    APPT_HEADING: "تعيينات",
    HEADER_TEXT: "تفاصيل الموعد",
    APPT_ID: "معرف الموعد",
    SLOT_START: "وقت بدء الفتحة",
    SLOT_END: "وقت انتهاء الفتحة",
    PAYMENT_STATUS: "حالة السداد",
    BOOKING_TIME: "وقت الحجز",
    APPT_STATUS: "حالة التعيين",
    PAYMENT_AMT: "مبلغ الدفع",
    UPDATE_BTN: "تحديث",
    PAYMENT_DETAILS: "بيانات الدفع",
    AMT: "كمية",
    CURRENCY: "عملة",
    METHOD: "طريقة",
    DESCRIPTION: "وصف",
    CARD_ID: "بطاقة الهوية",
    EMAIL: "البريد الإلكتروني",
    CONTACT: "اتصل",
    DATE: "تاريخ",
    SLOT: "فتحات"
  },
  PatientStrings: {
    PATIENT_DETAILS: "تفاصيل المريض",
    PATIENT_HEADING: "تفاصيل الملف الشخصي",
    EDIT: "تعديل",
    EMIRATES_TEXT4: "emiratesId",
    EMIRATES: "emiratesdId",
    MOBILE_TEXT: "رقم الهاتف المحمول",
    OTP: "otp",
    DOB: "دوب",
    ID: "هوية شخصية",
    AGE: "عمر",
    APPOINTMENT_COUNT: "الموعد",
    ALERT1: "أدخل البريد الإلكتروني",
    OTP_TEXT: "إنشاء OTP",
    SAVE_TEXT: "حفظ",
    CHANGE_TEXT: "يتغيرون",
    YEARS: "سنوات",
    FORMAT: "YYYY-MM-DD",
    NAME1: "الصورة الشخصية",
    NAME2: "emiratesIdDoc",
    NAME3: "التأمين",
    NAME4: "البريد الإلكتروني",
    OBJECT: "موضوع",
    DELETE_DATA: "حذف البيانات"
  },
  SpecialityStrings: {
    TEXT1: " حدد التخصص",
    TEXT2: "لا توجد بيانات لإظهارها",
    PLACEHOLDER: "اسم التخصص",
    BOOK_APPT: "موعد الكتاب",
    SEARCH_SPEC: "بحث التخصص",
    BANNER_TEXT: "لا يمكن حجز المواعيد كما طلبت حذف حسابك !!"
  },
  Currency: {
    INR: "روبية هندية",
    AED: "درهم إماراتي"
  },
  ConfigurationStrings: {
    HEADER_TEXT: "التكوينات",
    BOOKING_TIME: "حجز TIme (بالدقائق)",
    PAYMENT_TIME: "الوقت الكامل للدفع (بالدقائق)",
    SLOT_TIME: "مدة الفتحة (بالدقائق)",
  },
  tncHeadings: [
    "هوية شخصية",
    "الإصدار",
    "الحالة",
    "عمل",
    "رأي"
  ],
  DocumentType: {
    1: "وصفة طبية",
    2: "تقرير"
  },
  TemperatureUnit: [
    "F",
    "C"
  ],
  ProfileHeadings: [
    "تعيينات",
    "تجربة",
    "عن الطبيب",
    "رسوم الاستشارة",
    "لغة"
  ],
  SlotStatus: {
    1: "فتح",
    2: "مقفل",
    3: "محجوز"
  },
  Gender: [
    "الذكر",
    "أنثى"
  ],
  CallVia: {
    1: "محلي",
    2: "فيديو",
    3: "بحث"
  },
  PaymentStatus: {
    1: "لا يوجد",
    2: "قيد الانتظار",
    3: "فشل",
    4: "منجز",
  },
  PatientDetailHeadings: [
    "البريد الإلكتروني",
    "الاسم الاول",
    "الكنية",
    "رقم الهاتف المحمول",
    "تاريخ الولادة",
    "عمر",
    "جنس",
    "ارتفاع",
    "وزن",
    "هويه الإمارات",
    "وثيقة الإمارات",
    "وثيقة التأمين",
    "OTP"
  ],
  SpecialityHeadings: [
    "شعار",
    "اسم",
    "الحالة"
  ],
  AppointmentStatus: {
    1: "قيد الانتظار",
    2: "انتظار الدفع",
    3: "تم تأكيد",
    4: "مرفوض",
    5: "منتهية الصلاحية",
    6: "ألغيت",
    7: "فشل",
    8: "منتهية الصلاحية",
  },
  PaymentConfirmation: {
    1: "انتظار الدفع",
    2: "PayNow",
    3: "Start",
    4: "مرفوض",
    5: "منتهية الصلاحية",
    6: "ألغيت",
    7: "فشل",
    8: "منتهية الصلاحية",
  },
  AppointmentHeadings: [
    "اسم المريض",
    "اسم الطبيب",
    "تم الحجز في",
    "فتحة الوقت",
    "حالة السداد",
    "مبلغ الدفع",
    "حالة التعيين",
    "رسوم التعيين",
    "عمل",
  ],
  DoctorsDetailHeadings: [
    "معرف الطبيب",
    "اسم",
    "رسوم الاستشارة",
    "سنوات من الخبرة",
    "رقم الهاتف المحمول",
    "تاريخ الولادة",
    "البريد الإلكتروني",
    "معرف التخصصات",
    "تخصص",
    "المواعيد الإجمالية",
    "مؤهل طبيب",
    "تاريخ بدء التجربة",
    "حول"
  ],
  // PATIENT: [
  //   { text: "صورة الملف الشخصي", value: 'profileImage' },
  //   { text: "البريد الإلكتروني", value: 'email' },
  //   { text: "الاسم الاول", value: 'firstName' },
  //   { text: "الكنية", value: 'lastName' },
  //   { text: "رقم الهاتف المحمول", value: 'mobileNumber' },
  //   { text: "دوب", value: 'dob' },
  //   { text: "عمر", value: 'age' },
  //   { text: "جنس", value: 'gender' },
  //   { text: "ارتفاع", value: 'height' },
  //   { text: "وزن", value: 'weight' },
  //   { text: "هويه الإمارات", value: 'emiratesdId' },
  //   { text: "وثيقة هوية الإمارات", value: 'emiratesIdDoc' },
  //   { text: "وثيقة التأمين", value: 'insuranceDoc' }
  // ],
  // DOCTOR: [
  //   { text: "صورة الملف الشخصي", value: 'profileImage' },
  //   { text: "البريد الإلكتروني", value: 'email' },
  //   { text: "الاسم الاول", value: 'firstName' },
  //   { text: "الكنية", value: 'lastName' },
  //   { text: "دوب", value: 'dob' },
  //   { text: "رقم الهاتف المحمول", value: 'mobileNumber' },
  //   { text: "رسوم الاستشارة", value: 'consultationFee' },
  //   { text: "سنوات من الخبرة", value: 'yrsOfExp' },
  //   { text: "تخصص", value: 'specialityName' },
  //   { text: "المؤهل", value: 'medEducationName' },
  //   { text: "تاريخ بدء التجربة", value: 'experienceStartedFrom' },
  // ]
  PATIENT: [
    { text: "Profile Image", value: 'profileImage' },
    { text: "Email", value: 'email' },
    { text: "First Name", value: 'firstName' },
    { text: "Last Name", value: 'lastName' },
    { text: "Mobile Number", value: 'mobileNumber' },
    { text: "DOB", value: 'dob' },
    { text: "Age", value: 'age' },
    { text: "Gender", value: 'gender' },
    { text: "Height", value: 'height' },
    { text: "Weight", value: 'weight' },
    { text: "Emirates Id", value: 'emiratesdId' },
    { text: "EmiratesId Doc", value: 'emiratesIdDoc' },
    { text: "Insurance Doc", value: 'insuranceDoc' }
  ],
  DOCTOR: [
    { text: "Profile Image", value: 'profileImage' },
    { text: "Email", value: 'email' },
    { text: "First Name", value: 'firstName' },
    { text: "Last Name", value: 'lastName' },
    { text: "DOB", value: 'dob' },
    { text: "Mobile Number", value: 'mobileNumber' },
    { text: "Consultation Fee", value: 'consultationFee' },
    { text: "Years of Experience", value: 'yrsOfExp' },
    { text: "Speciality", value: 'specialityName' },
    { text: "Qualification", value: 'medEducationName' },
    { text: "Experience start date", value: 'experienceStartedFrom' },
  ],
  DELETE_MODAL: {
    TOTAL: "totalAppt",
    ROW1: "عدد المواعيد القادمة",
    ROW2: "هل أنت متأكد من حذف حسابك؟",
    BUTTON1: "نعم",
    BUTTON2: "إلغاء",
    ROW3: "ادخل رقمك السري",
    BUTTON3: "حذف",
    ROW4: "سيتم حذف حسابك بعد",
    ROW7: "أيام",
    BUTTON4: "حسنا",
    BUTTON5: "تعطيل",
    ROW5: "تم إبطال مفعولها بنجاح !!",
    ROW6: "تم إلغاء تنشيط حسابك لـ",
    ALERT: "رمز مرور خاطئ",
    ROW8: "تم إرسال طلب الحذف",
    ROW9: "قم بتسجيل الدخول مرة أخرى لإعادة تنشيط حسابك"
  },
  DELETE_REQUEST_STATUS: {
    0: 'لم يطلب',
    1: 'طلب',
    2: 'قيد المعالجة',
    3: 'قيد المراجعة',
    4: 'منجز',
  },
  ABOUT_US: {
    TEXT1: "تصل إلينا:",
    TEXT2: "support@tftus.com",
    TEXT3: "الاستشارة عن بعد هي استشارة مكالمة هاتفية مع أطبائنا. بسبب ضيق الوقت والمسافة ، قد يكون من الصعب عليك السفر فعليًا إلى مراكزنا. تتيح لك الاستشارات عبر الهاتف التحدث إلى أطبائنا وطلب الرعاية الطبية المناسبة دون عناء السفر شخصيًا.",
    TEXT4: "مزايا الاستشارات الهاتفية",
    TEXT5: "في هذا العالم المتصل بالهاتف والذي يتميز بدرجة عالية من التنقل في بعض الأحيان ، قد يكون تحديد المواعيد الشخصية أمرًا صعبًا. ومع ذلك لا ينبغي أن يحرمك ذلك من أفضل رعاية طبية.",
    TEXT6: "Tele-Consult هي مبادرة لتقديم خدمة رعاية صحية ذات مستوى عالمي إلى الموقع والوقت الذي يناسبك. قد تكون مسافرًا أو مقيدًا بجدولك الزمني - أينما ومتى - ما عليك سوى تسجيل الدخول والحصول على رعاية طبية ذات مستوى عالمي.",
    TEXT7: "مع أحدث التقنيات والبنية التحتية ، نجعل هذه التفاعلات عن بُعد حقيقية قدر الإمكان بالنسبة لك. وبالتالي ، لديك أفضل ما في العالمين. تساعدك الاستشارات عبر الهاتف في التغلب على تحديات المسافة والوقت. بالإضافة إلى ذلك ، فإنها توفر الراحة والملاءمة - كل شيء بنقرة زر واحدة.",
    TEXT8: "كيف يعمل TELE CONSULT؟"
  },
  BOX: {
    ICON_TEXT1: "الاتصال",
    ICON_TEXT2: "تؤكد",
    ICON_TEXT3: "شاور",
    HEADING1: "طلب موعد استشارة عن بعد",
    HEADING2: "تلقي تأكيد للتشاور",
    HEADING3: "أفضل رعاية طبية بضغطة زر",
    TEXT1: "احجز موعدًا مع طبيب من تخصص معين ، فهذا يساعدنا على فهم احتياجاتك بالضبط ويمكننا من مساعدتك وفقًا لذلك ، ادفع المبلغ (عبر الإنترنت أو نقدًا).",
    TEXT2: "بمجرد تحديث RCMexecutive لتحديث دفعتك ، ستتلقى إشعارًا بتحديث الدفع إذا تم الدفع عبر الإنترنت. وبمجرد اكتمال الدفع ، ستتلقى إشعارًا بتأكيد الموعد.",
    TEXT3: "استشر فريق الأطباء المتمرسين وذوي الخبرة عبر الهاتف وأنت مرتاح في موقعك. مع فريق الخبراء لدينا من الأطباء ، ستتلقى أفضل النصائح دون الحاجة إلى الخروج من منزلك."
  }
}





